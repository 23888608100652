// Navbar.js
import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import { StyledAppBar } from "./StyleNavbar";
import AppImage from "../../public/Assets/Images/logo white.svg";
const Navbar = ({ navbarMobile, handleMobile, handleMobileClose }) => {
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPos = window.scrollY;
      const point = 50;

      setShowNav(scrollPos < point);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledAppBar showNavbar={showNav}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={AppImage}
            style={{
              height: "40px",
              width: "40px",
              flexGrow: "1",
              // marginTop: "25%",

              // justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            alt="logo"
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { lg: "flex", xs: "none" },
              justifyContent: "center",
              alignItems: "center",

              // fontFamily: "Montserrat, sans-serif",
              fontWeight: "700",
              marginTop: { lg: "4%", xs: "5%", sm: "1%" },
              marginLeft: "10%",

              fontSize: { lg: "1.5rem", xs: "1rem" },
            }}
          >
            Foviatech
          </Typography>
        </Box>

        {/* Logo on the left */}

        {/* Links and Button on the right */}
        <Box
          sx={{
            display: { md: "flex", xs: "none", sm: "flex" },
            alignItems: "center",
            marginTop: "1%",
            color: "white",
            marginRight: "2%",
            gap: "3rem",
          }}
        >
          <Box>
            <Typography variant="h6">
              <a
                href="/"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Home
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              <a
                href="/link2"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Research
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              <a
                href="/link3"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Industry
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              <a
                href="/link4"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
              >
                Company
              </a>
            </Typography>
          </Box>

          <Button
            color="inherit"
            sx={{
              fontFamily: "Montserrat, sans-serif",
              border: "1px solid black",
              background: "white",
              color: "black",
              "&:hover": {
                borderImage:
                  "linear-gradient(90deg, #00ff00, #ff0000, #0000ff)",
                borderImageSlice: 1,
                background: "white",
              },
            }}
          >
            Login
          </Button>
        </Box>
        <MenuIcon
          onClick={handleMobile}
          sx={{
            display: { sm: "none", md: "none", lg: "none" },
            marginTop: "3%",
          }}
          fontSize="large"
        />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
