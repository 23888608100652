import styled from "@emotion/styled";
import { Box, keyframes } from "@mui/material";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FoviarMainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backdropFilter: "blur(10px)",
  gap: "1rem",
  background: theme.palette.primary.alternative,
  color: "white",
  [theme.breakpoints.down("md")]: {
    gap: "2rem",
    justifyContent: "flex-start",
    minHeight: "125vh",
  },
}));

export const FoviaHeaderContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  marginTop: "4%",
  height: "20vh",

  display: "flex",

  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",
  },
}));

export const FoviarContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "80vh",
  marginTop: "4%",
  gap: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    gap: "0rem",
    flexDirection: "column",
    marginTop: "10%",
    height: "80vh",
  },
}));

export const FoviarCardLayout = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "100%",
  // background: "red",
  display: "flex",
  gap: "1.5rem",
  color: "white",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0%",
    gap: "1.5rem",
  },
}));

// export const FoviarCard = styled(Box)(({ theme }) => ({
//   width: "50% !important",
//   height: "90%",
//   background: theme.palette.primary.main,
//   borderRadius: theme.shape.borderRadius,
//   transition: "width 0.5s",
//   animation: "$glow 1.5s infinite alternate",
//   "&:hover": {
//     width: "90% !important",
//   },
//   "&:not(:hover)": {
//     width: "40% ",
//   },
//   "@keyframes glow": {
//     "0%": {
//       boxShadow: `0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(255, 255, 255, 0.8), 0 0 40px rgba(255, 255, 255, 0.8)`,
//     },
//     "100%": {
//       boxShadow: `0 0 40px rgba(255, 255, 255, 0), 0 0 30px rgba(255, 255, 255, 0), 0 0 20px rgba(255, 255, 255, 0), 0 0 10px rgba(255, 255, 255, 0)`,
//     },
//   },
//   "& .background": {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundImage: `url(${ImageOne})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",

//     zIndex: -1,
//   },

//   "&::before": {
//     content: '""',
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     borderRadius: theme.shape.borderRadius,
//     zIndex: 1,
//     border: "3px solid rgba(255, 255, 255, 0.5)", // Border width and opacity for the glow effect
//     backdropFilter: "blur(10px) brightness(1.2)", // Adjust the backdrop filter for a stronger effect
//     backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color for the glassmorphic effect
//   },
// }));

// export const FoviarCard = styled(Box)(({ theme }) => ({
//   position: "relative",
//   width: "50%",
//   height: "90%",
//   background: theme.palette.primary.main,
//   borderRadius: theme.shape.borderRadius,
//   overflow: "hidden",
//   transition: "width 0.5s",

//   boxShadow: `0 0 20px rgba(255, 255, 255, 0.3)`,
//   "&:hover": {
//     width: "90%",
//     boxShadow: `0 0 40px rgba(255, 255, 255, 0.3)`,
//   },
//   "&::before": {
//     content: '""',
//     position: "absolute",
//     width: "100%",
//     height: "100%",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: "rgba(0, 0, 0, 0.25)",
//     zIndex: 1,
//   },
//   "&::after": {
//     content: '""',
//     position: "absolute",
//     width: "100%",
//     height: "100%",
//     boxShadow: `0 0 50px 20px rgba(255, 255, 255, 0.1)`,
//     transition: "box-shadow 0.5s",
//     zIndex: 2,
//   },
//   "&:hover::after": {
//     boxShadow: `0 0 70px 30px rgba(255, 255, 255, 0.1)`,
//   },
// }));

// export const FoviarText = styled(Typography)({
//   color: "white",
//   fontSize: "4rem",
//   position: "absolute",
//   top: "40%",
//   fontWeight: 700,
//   padding: "1rem",
//   textAlign: "center",
//   zIndex: "20",
// });

export const FoviarCard = styled(Box)(({ theme }) => ({
  position: "relative",
  // width: "50%",
  height: "90%",
  background: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  transition: "width 0.5s",
  boxShadow: `0 0 20px rgba(255, 255, 255, 0.2)`,
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    minHeight: "60%",
  },
  "&:hover": {
    width: "90%",
    boxShadow: `0 0 40px rgba(255, 255, 255, 0.1)`,

    "&::before": {
      backgroundColor: "rgba(0, 0, 0, 0)", // Hide the black overlay on hover
    },
    "&::after": {
      boxShadow: `0 0 0px 0px rgba(255, 255, 255, 0)`, // Remove the boxShadow on hover
    },
  },

  "&::before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    boxShadow: `0 0 50px 20px rgba(255, 255, 255, 0.1)`,
    transition: "box-shadow 0.5s",
    zIndex: 2,
  },
}));

export const HoverContent = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",

  top: 0,
  left: 0,
  overflowY: "scroll",

  [theme.breakpoints.down("md")]: {
    overflowY: "scroll",
    // height: "200%",

    // background: "red",
  },

  backgroundColor: "rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  zIndex: 980,

  borderRadius: "15px",
  backdropFilter: "blur(10px)",
  animation: `${fadeIn} 0.5s ease-in-out`,
  // animationDelay: "2s",
  "&.hide": {
    animation: `${fadeOut} 0.5s ease-in-out`, // Fade-out animation when hidden
  },
}));

export const FoviarCardHeader = styled(Box)({
  width: "80%",
  height: "5vh",
  marginTop: "2%",
  // marginBottom: "20%",

  // background: "red",
  display: "flex",
  justifyContent: "space-between",
});

export const CardComp = styled(Box)({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});
