import React, { useEffect, useState } from "react";
import {
  FovigraiCont,
  FovigraiHeaderCont,
  FovigraiContentCont,
  FovgraiOne,
} from "./FovigraiStyle";

import { Box, Typography } from "@mui/material";
import {
  FoviarCard,
  FoviarCardHeader,
  HoverContent,
} from "../Foviar-Hero/FoviarStyle";
import CardOneImage from "../../public/Assets/Images/ava.jpg";
import CardTwoImage from "../../public/Assets/Images/mobility.jpg";

const FovigraiHero = () => {
  const [cardOneWidth, setCardOneWidth] = useState("50%");
  const [cardTwoWidth, setCardTwoWidth] = useState("50%");
  const [showGlassmorphicBoxOne, setShowGlassmorphicBoxOne] = useState(false);
  const [showGlassmorphicBoxTwo, setShowGlassmorphicBoxTwo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleClickOne = () => {
    setCardOneWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
    setCardTwoWidth("50%");
    setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
    if (showGlassmorphicBoxTwo) {
      setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
    }
  };

  const handleClickTwo = () => {
    setCardTwoWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
    setCardOneWidth("50%");
    setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
    if (showGlassmorphicBoxOne) {
      setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
    }
  };

  const handleMobileClickOne = () => {
    setCardTwoWidth((prevWidth) => (prevWidth === "90%" ? "90%" : "90%"));
    setCardOneWidth("90%");
    setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
    if (showGlassmorphicBoxTwo) {
      setShowGlassmorphicBoxTwo(false);
    }
  };

  const handleMobileClickTwo = () => {
    setCardOneWidth((prevWidth) => (prevWidth === "90%" ? "90%" : "90%"));
    setCardTwoWidth("90%");
    setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
    if (showGlassmorphicBoxOne) {
      setShowGlassmorphicBoxOne(false);
    }
  };
  useEffect(() => {
    console.log("hi", window.innerWidth);
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 600);
      if (screenWidth <= 600) {
        setCardOneWidth("90%");
        setCardTwoWidth("90%");
      } else {
        // Set your default widths for larger screens
        setCardOneWidth("50%");
        setCardTwoWidth("50%");
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <FovigraiCont>
      <FovigraiHeaderCont>
        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "3.5rem", xs: "2rem" },
            fontWeight: "700",
            color: "#ededed",
            fontFamily: "monospace",
            textAlign: { xs: "center" },
          }}
        >
          FOVGRAI
        </Typography>
        <Typography
          variant="h3"
          sx={{
            marginTop: { xs: "5%", lg: "0%" },
            fontSize: { lg: "3rem", xs: "1.5rem" },
            fontWeight: "500",
            color: "#00c3ca",
            textAlign: { xs: "center" },
            fontFamily: "monospace",
          }}
        >
          &nbsp;AI-powered ergonomic design
        </Typography>
      </FovigraiHeaderCont>
      <FovigraiContentCont>
        <FovgraiOne>
          {isMobile ? (
            <>
              <FoviarCard
                onClick={handleMobileClickOne}
                style={{ width: cardOneWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${CardOneImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    zIndex: "2",
                  }}
                />
                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickOne}
                    style={{
                      position: "absolute",
                      top: "54%",
                      left: "30%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}
                {!showGlassmorphicBoxOne ? (
                  <Typography
                    variant="h1"
                    sx={{
                      width: "100%",
                      fontWeight: "700",
                      // height: "100%",
                      // background: "red",
                      zIndex: "100",
                      fontSize: "2rem",
                      position: "absolute",
                      top: "40%",
                      left: "30%",
                      // left: "100%",
                    }}
                  >
                    Aviation
                  </Typography>
                ) : null}
                {showGlassmorphicBoxOne && (
                  <HoverContent
                    className={showGlassmorphicBoxOne ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxOne(false)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        marginLeft: "4%",
                        marginTop: "5%",
                        animationDelay: showGlassmorphicBoxOne ? "2s" : "0s",
                      }}
                    >
                      Aviation
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",

                        fontSize: "0.8rem",
                        width: "90%",
                        marginLeft: "4%",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      Graphene and AI ‘Smart Seat’ interface enables materials
                      to adjust and interact with a passengers’ biometric data,
                      adapting the ergonomics of the cabin seat to improve the
                      customer’s flying experience. As a non-flammable, light
                      material, graphene adheres to the rigorous compliance and
                      weight requirements in aviation cabin design.
                    </Typography>
                  </HoverContent>
                )}
              </FoviarCard>
              <FoviarCard
                onClick={handleMobileClickTwo}
                style={{ width: cardTwoWidth }}
              >
                {!showGlassmorphicBoxTwo ? (
                  <Typography
                    variant="h1"
                    sx={{
                      width: "100%",
                      fontWeight: "700",
                      // height: "100%",
                      // background: "red",
                      zIndex: "100",
                      fontSize: "2rem",
                      position: "absolute",
                      top: "45%",
                      left: "29%",
                      // left: "100%",
                    }}
                  >
                    Mobility
                  </Typography>
                ) : null}

                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickTwo}
                    style={{
                      position: "absolute",
                      top: "58%",
                      left: "28%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${CardTwoImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />

                {showGlassmorphicBoxTwo && (
                  <HoverContent
                    className={showGlassmorphicBoxTwo ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxTwo(false)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1.5rem",
                        marginLeft: "4%",
                        marginTop: "5%",
                        fontWeight: "700",
                      }}
                    >
                      MOBILITY
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                        width: "80%",
                        marginLeft: "4%",
                      }}
                    >
                      The Mobility industry has made significant advancements in
                      IOT and AI interaction with real-world applications.
                      FOVIATECH, with its 2D Graphene and AI interface,
                      introduces the next leap in driving experience. Our
                      solution intuitively adapts the ergonomic environment of a
                      driver's seat in response to sensory feedback collected
                      and processed by our 2D Graphene and AI interface.
                    </Typography>
                  </HoverContent>
                )}
              </FoviarCard>
            </>
          ) : (
            <>
              <FoviarCard
                onClick={handleClickOne}
                style={{ width: cardOneWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${CardOneImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickOne}
                    style={{
                      position: "absolute",
                      top: "45%",
                      left: "30%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}

                {showGlassmorphicBoxOne && (
                  <HoverContent
                    className={showGlassmorphicBoxOne ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxOne(false)}
                  >
                    {/*<Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "4.5rem",
                        marginLeft: "4%",
                        marginTop: "10%",
                        animationDelay: showGlassmorphicBoxOne ? "2s" : "0s",
                        color: "white",
                      }}
                    >
                      Aviation
                    </Typography>*/}
                    <Typography
                      variant="span"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // marginTop: "25%",
                        fontWeight: "500",
                        marginLeft: "7%",
                        // textAlign: "center",
                        fontSize: "1.1rem",
                        width: "90%",
                        marginLeft: "4%",
                        color: "white",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      Graphene and AI ‘Smart Seat’ interface enables materials
                      to adjust and interact with a passengers’ biometric data,
                      adapting the ergonomics of the cabin seat to improve the
                      customer’s flying experience. As a non-flammable, light
                      material, graphene adheres to the rigorous compliance and
                      weight requirements in aviation cabin design.
                    </Typography>
                  </HoverContent>
                )}
              </FoviarCard>
              <FoviarCard
                onClick={handleClickTwo}
                style={{ width: cardTwoWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${CardTwoImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {!showGlassmorphicBoxTwo ? (
                  <button
                    onClick={handleClickTwo}
                    style={{
                      position: "absolute",
                      top: "45%",
                      left: "35%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}

                {showGlassmorphicBoxTwo && (
                  <HoverContent
                    className={showGlassmorphicBoxTwo ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxTwo(false)}
                  >
                    {/*<Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "4.5rem",
                        marginLeft: "2%",
                        marginTop: "10%",
                        color: "white",
                        animationDelay: showGlassmorphicBoxTwo ? "2s" : "0s",
                      }}
                    >
                      Automotive
                    </Typography>*/}
                    <Typography
                      variant="span"
                      sx={{
                        marginLeft: "5%",
                        // textAlign: "center",
                        fontWeight: "500",
                        // marginTop: "25%",
                        fontSize: "1.1rem",
                        width: "90%",
                        // marginLeft: "2%",
                        color: "white",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      The Mobility industry has made significant advancements in
                      IOT and AI interaction with real-world applications.
                      FOVIATECH, with its 2D Graphene and AI interface,
                      introduces the next leap in driving experience. Our
                      solution intuitively adapts the ergonomic environment of a
                      driver's seat in response to sensory feedback collected
                      and processed by our 2D Graphene and AI interface.
                    </Typography>
                  </HoverContent>
                )}
              </FoviarCard>
            </>
          )}
        </FovgraiOne>
      </FovigraiContentCont>

      {!isMobile ? (
        <FoviarCardHeader>
          <Typography
            variant="h6"
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              position: "relative",
              bottom: "4.5rem",
              left: "3rem",
              cursor: "pointer",
              color: "white",
            }}
            onClick={handleClickOne}
          >
            AVIATION
          </Typography>
          <Typography
            onClick={handleClickTwo}
            variant="h6"
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              position: "relative",
              bottom: "4.5rem",
              cursor: "pointer",
              color: "white",
              right: "3rem",
            }}
          >
            MOBILITY
          </Typography>
        </FoviarCardHeader>
      ) : null}
    </FovigraiCont>
  );
};

export default FovigraiHero;

// <FovgraiOne>
//           <FovgraiImageComp>
//             <Typography variant="h5">Sample Image</Typography>
//           </FovgraiImageComp>
//           <FovgariContentComp>
//             <Typography
//               sx={{ fontWeight: "700", marginLeft: "3%", marginTop: "2%" }}
//               variant="h4"
//             >
//               Aviation
//             </Typography>
//             <Typography
//               sx={{
//                 width: "90%",
//                 marginLeft: "3%",
//                 marginTop: "3%",
//                 fontSize: "1.1rem",
//               }}
//               variant="span"
//             >
//               Graphene and AI ‘Smart Seat’ interface enables materials to adjust
//               and interact with a passengers’ biometric data, adapting the
//               ergonomics of the cabin seat to improve the customer’s flying
//               experience. As a non-flammable, light material, graphene adheres
//               to the rigorous compliance and weight requirements in aviation
//               cabin design.
//             </Typography>
//           </FovgariContentComp>
//         </FovgraiOne>
//         <FovgraiOne>
//           <FovgraiImageComp>
//             <Typography variant="h5">Sample Image</Typography>
//           </FovgraiImageComp>
//           <FovgariContentComp>
//             <Typography
//               sx={{ fontWeight: "700", marginLeft: "3%", marginTop: "2%" }}
//               variant="h4"
//             >
//               Automotive
//             </Typography>
//             <Typography
//               sx={{
//                 width: "90%",
//                 marginLeft: "3%",
//                 marginTop: "3%",
//                 fontSize: "1.1rem",
//               }}
//               variant="span"
//             >
//               The automotive industry has made significant advancements in IOT
//               and AI interaction with real-world applications. FOVIATECH, with
//               its 2D Graphene and AI interface, introduces the next leap in
//               driving experience. Our solution intuitively adapts the ergonomic
//               environment of a driver's seat in response to sensory feedback
//               collected and processed by our 2D Graphene and AI interface.
//             </Typography>
//           </FovgariContentComp>
//         </FovgraiOne>
