import React from "react";
import {
  FirstContentDiv,
  FirstContentTypo,
  FutureContainer,
  FutureContentDiv,
  SecondContentDiv,
  SecondDiv,
} from "./FutureStyle";

import { Typography } from "@mui/material";
import Cube from "../../public/Assets/Images/cube.png";
import { Element } from "react-scroll";
import bgVideo from "../../public/Assets/Video/107.mov";


const FutureHero = () => {
  return (
    <Element name="futureHero">
      <FutureContainer>
      <video 
          autoPlay 
          loop 
          muted 
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1",
            opacity: 0.1,
          }}
          >
          <source src={bgVideo} type="video/mp4" />
        </video>
        <FutureContentDiv sx={{ marginTop: { xs: "10%", lg: "0%" } }}>
          <FirstContentDiv>
            <FirstContentTypo>
              <Typography
                variant="h3"
                sx={{
                  marginLeft: { lg: "8%", xs: "0%" },
                  fontSize: { lg: "2.25rem", xs: "1.75rem" },
                  fontWeight: "700",
                  width: { lg: "80%", xs: "100%" },
                  marginTop: "5%",
                  color: "white",
                  fontFamily:"IBM Plex Sans"
                }}
              >
                Our AI-Powered Solutions
                <text
                  style={{
                    fontWeight: "300",
                    fontSize: { lg: "1.0rem", xs: "1.25rem" },
                  }}
                >
                  {" "}
                  for cross-industry innovation{" "}
                </text>
              </Typography>
            </FirstContentTypo>
            <FirstContentTypo>
              <Typography
                variant="span"
                sx={{
                  fontSize: { lg: "1.0rem", xs: "0.8rem" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginLeft: "5%",
                  width: { lg: "90%", xs: "100%" },
                  marginTop: { lg: "5%", xs: "0%" },
                }}
              >
                Aviation, Mobility, and Manufacturing industries are looking to
                AI to power new levels of efficiency, accuracy and customer
                experience, embracing the next leap of innovation to establish a
                competitive advantage.
              </Typography>
            </FirstContentTypo>
          </FirstContentDiv>
          <SecondContentDiv>
            <SecondDiv sx={{ marginTop: { xs: "10%" } }}>
              {/* <img
                src={Cube}
                alt="Your"
                style={{
                  width: "30px",
                  height: "30px",
                  "@media (max-width: 600px)": {
                    width: "20px",
                    height: "20px",
                  },
                }}
              />*/}

              <Typography
                variant="h6"
                sx={{
                  fontSize: { lg: "2.0rem", xs: "1.5rem" },
                  fontWeight: "700",
                  height: "40%",
                  // background: "yellow",
                  width: "100%",
                  marginLeft: { lg: "8%", xs: "0%" },
                  fontFamily:"IBM Plex Sans"
                }}
              >
                FOVIAR
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { lg: "1.2rem", xs: "1.0rem" },
                  fontWeight: "400",
                  height: "40%",
                  // background: "yellow",
                  width: "100%",
                  marginTop: { lg: "-10%", xs: "0%" },
                  marginLeft: { lg: "50px", xs: "0%" },
                  fontFamily: "monospace",
                }}
              >
                AI-driven Digital Twin
              </Typography>
              <Typography
                variant="span"
                sx={{
                  fontSize: { lg: "1.0rem", xs: "0.8rem" },
                  width: { lg: "80%", xs: "100%" },
                  // marginTop: "0%",
                  marginTop: { lg: "-10%", xs: "15%" },
                  marginLeft: { lg: "8%", xs: "0%" },
                }}
              >
                A proprietary digital twin platform using AI technology to
                create digital replicas of parts. Combined with industrial
                vision technologies, we rapidly assess production or maintenance
                accuracy, damage tolerance, and fault detection.
              </Typography>
            </SecondDiv>
            <SecondDiv sx={{ marginTop: { xs: "15%", lg: "10%" } }}>
              {/*<img
                src={Cube}
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5%",
                  "@media (max-width: 600px)": {
                    width: "20px",
                    height: "20px",
                  },
                }}
                alt="cube"
              />*/}
              <Typography
                variant="h6"
                sx={{
                  fontSize: { lg: "2.0rem", xs: "1.5rem" },
                  fontWeight: "700",
                  height: "40%",
                  // background: "yellow",
                  width: "100%",
                  fontFamily:"IBM Plex Sans",
                  marginLeft: { lg: "0%", xs: "0%" },
                }}
              >
                FOVGRAI
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { lg: "1.2rem", xs: "1.0rem" },
                  fontWeight: "400",
                  height: "40%",
                  fontFamily: "monospace",
                  // background: "yellow",
                  width: "100%",
                  marginTop: { lg: "-10%", xs: "0%" },
                  marginLeft: { lg: "0%", xs: "0%" },
                }}
              >
                Ergonomics design and sensor analytics
              </Typography>
              <Typography
                variant="span"
                sx={{
                  fontSize: { lg: "1.0rem", xs: "0.8rem" },
                  width: { lg: "80%", xs: "100%" },
                  // marginTop: "0%",
                  marginTop: { lg: "-10%", xs: "15%" },
                  marginLeft: "0%",
                }}
              >
                Our AI-infused Graphene sensor interface enables materials to
                adjust and interact to increase the ergonomic application in a
                product that engages with the human body. We elevate the
                experience and safety in seating systems in aviation, defense,
                e-bikes and automobiles.
              </Typography>
            </SecondDiv>
          </SecondContentDiv>
        </FutureContentDiv>
      </FutureContainer>
    </Element>
  );
};

export default FutureHero;

// <SecondContentDiv>
//             <SecondDiv>
//               <img
//                 src={Cube}
//                 style={{ width: "40px", height: "40px" }}
//                 alt="cube"
//               />
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "1.4rem",
//                   fontWeight: "700",
//                   width: "100%",
//                   marginTop: "2%",
//                 }}
//               >
//                 FOVIAR - AI-driven Digital Twin:
//               </Typography>
//               <Typography
//                 variant="span"
//                 sx={{
//                   fontSize: "1rem",
//                   width: "80%",
//                   marginTop: "2%",
//                 }}
//               >
//                 A proprietary digital twin platform using AI technology to
//                 create digital replicas of parts. Combined with industrial
//                 vision technologies, we rapidly assess production or maintenance
//                 accuracy, damage tolerance, and fault detection.
//               </Typography>
//             </SecondDiv>
//             <SecondDiv>
//               <img
//                 src={Cube}
//                 style={{ width: "40px", height: "40px", marginLeft: "5%" }}
//                 alt="cube"
//               />
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontSize: "1.4rem",
//                   fontWeight: "700",
//                   width: "100%",
//                   marginTop: "2%",
//                   marginLeft: "5%",
//                 }}
//               >
//                 FOVGRAI - Ergonomics design and sensor analytics:
//               </Typography>
//               <Typography
//                 variant="span"
//                 sx={{
//                   fontSize: "1rem",
//                   width: "80%",
//                   marginTop: "2%",
//                   marginLeft: "5%",
//                 }}
//               >
//                 Our AI-infused Graphene sensor interface enables materials to
//                 adjust and interact to increase the ergonomic application in a
//                 product that engages with the human body. We elevate the
//                 experience and safety in seating systems in aviation, defense,
//                 e-bikes and automobiles.
//               </Typography>
//             </SecondDiv>
//           </SecondContentDiv>
// <FirstContentDiv>
//   <FirstContentTypo>
//     <Typography
//       variant="h3"
//       sx={{
//         fontSize: "2.25rem",
//         fontWeight: "700",
//         width: "80%",
//         marginTop: "5%",
//       }}
//     >
//       Use Cases
//     </Typography>
//   </FirstContentTypo>
//   <FirstContentTypo>
//     <Typography
//       variant="span"
//       sx={{
//         fontSize: "1.2rem",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         marginLeft: "5%",
//         width: "90%",
//         marginTop: "5%",
//       }}
//     >
//       We deliver AI-driven interfaces that allow humans and machines to work
//       better together - optimizing efficiency, accuracy, safety and user
//       experience. Our goal is to help manufacturing, maintenance and design keep
//       up with the next innovation leap.
//     </Typography>
//   </FirstContentTypo>
// </FirstContentDiv>;

// <FirstContentDiv sx={{ marginTop: { lg: "5%" } }}>
//   <FirstContentTypo>
//     <Typography
//       variant="h3"
//       sx={{
//         fontSize: { lg: "2.25rem", xs: "1.75rem" },
//         fontWeight: "700",
//         width: { lg: "80%", xs: "100%" },
//         marginTop: "5%",
//       }}
//     >
//       Use Cases
//     </Typography>
//   </FirstContentTypo>
//   <FirstContentTypo>
//     <Typography
//       variant="span"
//       sx={{
//         fontSize: { lg: "1.2rem", xs: "1rem" },
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         // marginLeft: "5%",
//         width: { lg: "90%", xs: "100%" },
//         marginTop: { lg: "5%", xs: "-10%" },
//       }}
//     >
//       We deliver AI-driven interfaces that allow humans and machines to work
//       better together - optimizing efficiency, accuracy, safety and user
//       experience. Our goal is to help manufacturing, maintenance and design keep
//       up with the next innovation leap.
//     </Typography>
//   </FirstContentTypo>
// </FirstContentDiv>;
