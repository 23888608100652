import React from 'react';
import { Box, Container, Typography, Grid, IconButton, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Imprint = () => {
    return (
        <Container>
            <Box sx={{ paddingTop: '100px', minHeight: '100vh' }}>
                <Typography variant="h4" gutterBottom>
                    Imprint
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" gutterBottom>
                            <strong>FOVIATECH GmbH</strong> <br />
                            Raboisen 32,<br />
                            20095 Hamburg<br />
                            GERMANY
                            <Typography>
                                <Link
                                    href="mailto:info@foviatech.com"
                                    underline="hover"
                                    sx={{
                                        color: '#4f4f4f', // Default color
                                        '&:hover': {
                                            color: 'blue' // Color on hover
                                        }
                                    }}
                                >
                                    CONTACT: info@foviatech.com
                                </Link>
                            </Typography><br></br>
                            Sowmya Thyagarajan <br></br>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" gutterBottom>
                            <strong>Geschäftsführerin / CEO</strong> <br /> Sowmya Thyagarajan
                        </Typography>

                    </Grid>
                </Grid>
                <br></br>
                <Typography variant="body1" gutterBottom>
                    <strong>Commercial register/Handelsregister</strong>
                    <br></br> Registered at civil court Hamburg / Germany HRB 151494
                </Typography>
                <Typography variant="body1" gutterBottom>
                    VAT ID: DE318095100
                </Typography>
                <br></br>
                <Typography variant="body1" gutterBottom>
                    Die im Impressum aufgeführten Personen distanzieren sich ausdrücklich von sämtlichen Inhalten verlinkter Seiten oder Grafiken und übernehmen keinerlei Verantwortung dafür. Jegliche Verstöße gegen geltendes Recht, Sitte oder Moral, die bekannt werden, führen unverzüglich zur Löschung von Links, Beiträgen, Einträgen, Grafiken oder ähnlichen Inhalten.
                </Typography>
                <Typography variant="body1">
                    Die Markennamen, die auf dieser Website genannt werden, sind Eigentum ihrer jeweiligen Rechteinhaber.
                </Typography>
                <br></br>
                <Typography variant="body1">
                    The persons named in the imprint hereby expressly distance themselves from all contents of linked pages or graphics and do not adopt them as their own under any circumstances. Any violations of applicable law, custom or morality that become known will result in the immediate deletion of links, contributions, entries, graphics or similar. The brand names mentioned on this website are the property of their respective owners.
                </Typography>
                <br></br>
                <Typography variant="body1">
                    <strong>Copyright/Urheberrecht:</strong>
                    <br></br>
                    <br></br>
                    Die auf diesen Seiten von den Seitenbetreibern erstellten Inhalte und Werke unterliegen dem deutschen Urheberrecht. Jegliche Vervielfältigung, Bearbeitung, Verbreitung oder Nutzung außerhalb der Grenzen des Urheberrechts bedarf der schriftlichen Zustimmung des jeweiligen Autors oder Erstellers. Das Herunterladen und Kopieren dieser Seite ist nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf diesen Seiten nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet und entsprechend gekennzeichnet. Sollten Sie dennoch eine Urheberrechtsverletzung feststellen, bitten wir um eine entsprechende Mitteilung. Bei Bekanntwerden von Rechtsverletzungen werden wir die betreffenden Inhalte umgehend entfernen.
                    <br></br>
                    <br></br>
                    The content and works created by the site operators on these pages are subject to German copyright law. Any duplication, processing, distribution or use outside the limits of copyright law requires the written consent of the respective author or creator. Downloading and copying this site is only permitted for private, non-commercial use. Insofar as the content on these pages was not created by the operator, the copyrights of third parties are respected and marked accordingly. Should you nevertheless discover a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove the content in question immediately.
                    <br></br>
                    <br></br>
                    Dieses Impressum gilt ebenfalls für die unten aufgeführten Social-Media-Profile:
                </Typography>
                <IconButton
                    onClick={() => window.open('https://www.linkedin.com/company/foviatech', '_blank')}
                    aria-label="LinkedIn"
                >
                    <LinkedInIcon />
                </IconButton>
            </Box>
        </Container>
    );
};

export default Imprint;
