import { styled } from "@mui/system";

// export const StyledAppBar = styled("div")(({ theme }) => {
//   const customTheme = useTheme();
//   return {
//     background: customTheme.palette.appBar.main,
//     borderBottom: "1px solid black",
//     width: "100%",
//     height: "4rem",
//     position: "fixed",
//     zIndex: 900,
//     color: "white",
//   };
// });

export const StyledAppBar = styled("div")(({ theme, showNavbar }) => ({
  background: "#1a212c",
  borderBottom: "1px solid black",
  width: "100%",
  height: "4.5rem",
  position: "fixed",
  zIndex: 900,
  color: "white",
  opacity: showNavbar ? 1 : 0,
  pointerEvents: showNavbar ? "auto" : "none",
  transition: "opacity 0.3s ease-out",
}));
