import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const FutureContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  backdropFilter: "blur(10px)",
  alignContent: "center",

  // background: "yellow",

  background: theme.palette.primary.alternative,
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  [theme.breakpoints.down("md")]: {
    // background: "red",
    height: "105vh",
  },
}));

export const FutureContentDiv = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "70%",
  display: "flex",
  flexDirection: "column",
  // background: "red",

  color: "white",
  [theme.breakpoints.down("md")]: {
    height: "125vh",
  },
}));

export const FirstContentDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "30vh",
  color: "white",
  display: "flex",
  // background: "blue",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    gap: "0.6rem",
    height: "30vh",
  },
}));

export const FirstContentTypo = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const SecondContentDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "40vh",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    height: "40vh",
    flexDirection: "column",
    minHeight: "60vh",
  },
}));

export const SecondDiv = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "40vh",
  display: "flex",
  // background: "red",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    height: "50%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

export const bulletCube = styled("img")({
  width: "30px",
  height: "30px",
});
