import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const FovigraiCont = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.alternative,
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  backdropFilter: "blur(10px)",
  color: "white",
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-start",
    gap: "2rem",
    minHeight: "125vh",
  },
}));

export const FovigraiHeaderCont = styled(Box)(({ theme }) => ({
  width: "80%",
  marginTop: "4%",
  height: "20vh",
  //   background: "yellow",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "column",

    // marginTop: "-30%",
  },
}));

export const FovigraiContentCont = styled(Box)(({ theme }) => ({
  marginTop: "4%",
  width: "100%",
  gap: "2rem",
  height: "80vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    gap: "0rem",
    flexDirection: "column",
    marginTop: "10%",
    height: "80vh",
  },
}));

export const FovgraiOne = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "100%",
  gap: "1.5rem",
  color: "white",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0%",
    gap: "1.5rem",
  },
}));

// export const FovgraiImageComp = styled(Box)({
//   width: "100%",
//   height: "60%",
//   background: "#dbdbdb",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// });

// export const FovgariContentComp = styled(Box)({
//   width: "100%",
//   height: "40%",
//   //   background: "red",
//   marginTop: "2%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "flex-start",
// });
