import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";

const marquee = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const ContactContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  // height: "100vh",
  height: "70vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "2rem",
  alignItems: "center",
  background: theme.palette.primary.alternative,
  // background: "red",

  [theme.breakpoints.down("md")]: {},
}));

export const UsedContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "25vh",
  display: "flex",
  flexDirection: "column",
  background: "white",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  overflow: "hidden",
}));

export const CompanyCont = styled(Box)(({ theme }) => ({
  width: "90%",
  marginLeft: "40%",
  height: "50%",
  //   background: "yellow",
  overflow: "hidden", // Ensure overflow is hidden to create a marquee effect
  position: "relative",
  //   backgroundColor: "red",
}));

export const MarqueeTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "700",
  whiteSpace: "nowrap",
  animation: `${marquee} 10s linear infinite`,
  position: "absolute",
  width: "100%",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  transform: "translateX(100%)", // Start each element outside the container
  animationDelay: "1s", // Add a delay for each element
  "&:nth-child(1)": {
    animationDelay: "0s",
  },
  "&:nth-child(2)": {
    animationDelay: "2s",
  },
  "&:nth-child(3)": {
    animationDelay: "4s",
  },
  "&:nth-child(4)": {
    animationDelay: "6s",
  },
}));

export const TouchContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "30vh",
  //   background: "red",
  display: "flex",
  flexDirection: "column",
  gap: "0.8rem",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const TouchButton = styled(Button)(({ theme }) => ({
  background: "white",
  width: "20%",
  color: "black",
  marginLeft: "5%",
  fontWeight: "700",
  marginTop: "4%",
  borderRadius: 0,
  height: "35%",
  transition: "background 0.3s",
  textTransform: "capitalize",
  fontFamily:"monospace",
  fontSize:"1rem",

  [theme.breakpoints.down("sm")]: {
    width: "50%",
    marginTop: "5%",
    // marginRight: "10%",
  },
  "&:hover": {
    background: "white",
    color: "black",
    border: "1px solid black",
  },
}));
