import React from "react";
import HeroSection from "../../Hero-Section/HeroSection";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import FutureHero from "../../Future-Hero/FutureHero";
import FoviarHero from "../../Foviar-Hero/FoviarHero";
import FovigraiHero from "../../Fovigrai-Hero/FovigraiHero";
import WorkflowHero from "../../Workflow-Hero/WorkflowHero";
import ScienceFuture from "../../Science-Future/ScienceFuture";
import ContactHero from "../../Contact-Hero/ContactHero";
import Footer from "../../../Footer/Footer";

const LandingpageContainer = styled(Box)({
  minHeight: "100vh",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  margin: 0,
  position: "absolute",
  top: "0%",
  overflowX: "hidden",
  // background: "green",
});

const FullScreenHeight = styled(Box)({
  flex: 2,
  display: "flex",
  flexDirection: "column",
});

const LandingPage = ({ refProp }) => {
  return (
    <LandingpageContainer>
      <FullScreenHeight ref={refProp} id="section1">
        <HeroSection />
      </FullScreenHeight>
      <FullScreenHeight id="section2">
        <FutureHero />
      </FullScreenHeight>
      <FullScreenHeight id="section3">
        <FoviarHero />
      </FullScreenHeight>
      <FullScreenHeight>
        <FovigraiHero />
      </FullScreenHeight>
      <FullScreenHeight>
        <WorkflowHero />
      </FullScreenHeight>
      <FullScreenHeight>
        <ScienceFuture />
      </FullScreenHeight>
      <FullScreenHeight>
        <ContactHero />
      </FullScreenHeight>
      <Footer />
    </LandingpageContainer>
  );
};

export default LandingPage;
