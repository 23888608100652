import React from "react";
import {
  ScienceCardDiv,
  ScienceContentCont,
  ScienceImageCont,
} from "./ScienceStyle";
import { Typography, colors } from "@mui/material";
import ImageOne from "../../public/Assets/Images/pexels-cottonbro-studio-4630669.jpg";

const ScienceCard = ({ title, contentOne, contentTwo, imageSrc }) => {
  return (
    <ScienceCardDiv>
      <ScienceImageCont>
        <img
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "15px",
          }}
        />
      </ScienceImageCont>
      <ScienceContentCont>
        <Typography
          variant="h6"
          sx={{ fontWeight: "700", fontFamily: "IBM Plex Sans" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            width: { lg: "90%", xs: "90%" },
            fontSize: { lg: "0.8rem", xs: "0.75rem" },
            fontFamily: "IBM Plex Sans",
          }}
        >
          {contentOne}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            width: { lg: "90%", xs: "90%" },
            fontSize: { lg: "0.8rem", xs: "0.75rem" },
            fontFamily: "IBM Plex Sans",
            // width: "90%",
            // fontSize: "0.8rem",
          }}
        >
          {contentTwo}
        </Typography>
      </ScienceContentCont>
    </ScienceCardDiv>
  );
};

export default ScienceCard;
