import styled from "@emotion/styled";
import { Box, keyframes } from "@mui/material";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const WorkFlowContainer = styled(Box)(({ theme }) => ({
  minHeight: "125vh",
  width: "100%",
  background: theme.palette.primary.alternative,
  // background: "red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    // background: "brown",
    minHeight: "125vh",
  },
}));

export const WorkFlowContent = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "125vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  gap: "0rem",
  [theme.breakpoints.down("md")]: {
    // background: "green",
    height: "125vh",
  },
}));

export const WorkFlowHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "20vh",
  // background: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // marginTop: "30%",

  [theme.breakpoints.down("md")]: {
    marginTop: "4%",
  },
}));

export const WorkFlowCardComp = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "80vh",
  marginTop: "5%",
  display: "flex",
  gap: "1rem",
  // marginTop: "14%",
  justifyContent: "center",
  alignItems: "center",
  // background: "cyan",
  [theme.breakpoints.down("md")]: { position: "relative", top: "-5%" },
}));

export const WorkFlowLeftCards = styled(Box)(({ theme }) => ({
  width: "40%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex- start",
  alignItems: "center",
  gap: "2rem",
  color: "white",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  // background: "cyan",
}));

export const WorkFlowRight = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  // background: "grey",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const CardNotExp = styled(Box)({
  width: "90%",
  height: "12vh",
  zIndex: "900",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  fontWeight: "700",
  // border: "1px solid white",
  borderRadius: "15px",
  transition:
    "background-color 0.3s ease-in, color 0.3s ease-in height 0.3s ease-in",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#1ec7c9",
    color: "black",
  },
});

export const CardExp = styled(Box)(
  {
    width: "90%",
    maxHeight: "1440px",
    borderRadius: "15px",
    display: "flex",
    zIndex: "900",
    flexDirection: "column",
    // background: "#141a21",
    background: "#116466",

    gap: "1rem",
    transition: "height 0.3s ease-out",
    animation: `${fadeIn} 0.5s ease-in-out`,
    paddingBottom: "5%",

    "&.hide": {
      animation: `${fadeOut} 0.5s ease-in-out`, // Fade-out animation when hidden
    },
  },
  (props) => ({
    maxHeight: !props.cardState ? "1440px" : "15%", // Set the height based on cardStateOne
  })
);

export const ImageBox = styled(Box)({
  width: "90%",
  maxHeight: "75vh",
  background: "white",
  borderRadius: "20px",
  // marginLeft: "10%",
  position: "relative",
  overflow: "hidden",
  boxShadow: "0 0 50px rgba(255, 255, 255, 0.3)",
  animation: `${fadeIn} 0.5s ease-in-out`,
  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
  "&:before": {
    content: '""',
    position: "absolute",
    top: "-5%",
    left: "-5%",
    width: "110%",
    height: "110%",
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
    borderRadius: "20px",
  },
  "&.hide": {
    animation: `${fadeOut} 0.5s ease-in-out`, // Fade-out animation when hidden
  },
});
