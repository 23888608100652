import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const HeroDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.alternative,
  // background: "red",
  height: "100vh",
  width: "100%",
  color: "white",

  // borderBottom: "2px solid black",
  backdropFilter: "blur(10px)",
  transition: "background-color 0.3s ease",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("md")]: {
    background: theme.palette.primary.alternative,
    // background: "red",
    height: "100vh",
  },
}));

export const GlassBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  backdropFilter: "blur(20px)",
  borderRadius: "15px",
  boxShadow: `0 0 20px 10px rgba(255, 255, 255, 0)`,
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  position: "absolute",
  display: "flex",
  top: 0,
  left: 0,
}));
export const ContentBox = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "80%",
  position: "relative",
  marginTop: "5%",
  display: "flex",
  // top: "15%",
  // left: "5%",
  backdropFilter: "blur(10px)", // Apply background blur
  borderRadius: "15px", // Add rounded corners for glassmorphism effect
  background: "rgba(28, 54, 90, 0.7)",
  boxShadow: `0 0 20px 0px rgba(255, 255, 255, 0.2)`,

  // backdropFilter: "blur( 3.5px )",
  // border: "1px solid rgba( 255, 255, 255, 0.18 )",
  overflow: "hidden", // Hide overflowing conten

  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    boxShadow: `0 0 50px 20px rgba(255, 255, 255, 0.1)`,
    transition: "box-shadow 0.5s",
    zIndex: 2,
  },

  "& video": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: "-1",
    backdropFilter: "blur(1px)",
  },

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
  },
  "&:hover": {
    animation: "$rotateFrames 1s steps(36) forwards", // 36 frames for a full rotation
  },
  "@keyframes rotateFrames": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
// "&:hover": {
//   background: `linear-gradient(to right, #1c2f4d, #4d351c) no-repeat`,
//   backgroundSize: "200% 100%",
//   animation: "$gradientMove 1s linear infinite",
// },
// "@keyframes gradientMove": {
//   "0%": {
//     backgroundPosition: "100% 50%",
//   },
//   "100%": {
//     backgroundPosition: "0 50%",
//   },
// },

// export const ContentBox = styled(Box)({
//   position: "relative",
//   width: "90%",
//   height: "80%",
//   top: "15%",
//   left: "5%",
//   display: "flex",
//   borderRadius: "10px",
//   overflow: "hidden",
//   boxShadow: "0 0 10px 5px transparent, 0 0 20px 10px transparent", // Initial transparent border
//   transition: "box-shadow 0.5s ease", // Smooth transition for the glow effect
//   backdropFilter: "blur(10px)",
//   "&:hover": {
//     boxShadow: "0 0 20px 10px #1c365a, 0 0 40px 20px #1c365a", // Adjusted glowing border on hover
//   },
//   "&::before, &::after": {
//     content: '""',
//     position: "absolute",
//     width: "100%",
//     height: "100%",
//     borderRadius: "10px",
//     zIndex: "-1",
//   },
//   "&::before": {
//     boxShadow: "inset 0 0 20px 10px #1c365a", // Inner glow effect
//   },
//   "@keyframes rotateFrames": {
//     "0%": {
//       transform: "rotate(0deg)",
//     },
//     "100%": {
//       transform: "rotate(360deg)",
//     },
//   },
// });

// export const ContentBox = styled(Box)({
//   width: "90%",
//   height: "80%",
//   //   background: "yellow",
//   position: "relative",
//   top: "10%",
//   left: "5%",
//   display: "flex",
//   marginTop: "3%",
//   backdropFilter: "blur(10px)",
//   borderRadius: "10px",
//   backgroundColor: "rgba(255, 255, 255, 0.1)",
//   transition: "background 0.3s ease", // Add transition for a smooth effect
//   overflow: "hidden", // Hide overflowing content
//   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   "&:hover": {
//     background: "linear-gradient(to right, #ff8a00, #da1b60)", // Add gradient on hover
//     animation: "gradientMove 2s linear infinite", // Add animation on hover
//   },
//   "@keyframes gradientMove": {
//     "0%": {
//       backgroundPosition: "0% 50%", // Initial position
//     },
//     "100%": {
//       backgroundPosition: "100% 50%", // Final position
//     },
//   },
// });

export const FirstContent = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
}));

export const BlackButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: "58%",
  left: "18%",
  background: "white",
  width: "20%",
  color: "black",
  fontWeight: "700",
  marginTop: "5%",
  borderRadius: theme.shape.borderRadius,
  height: "8%",
  transition: "background 0.3s",
  textTransform: "capitalize",

  [theme.breakpoints.down("sm")]: {
    width: "50%",
    marginRight: "0%",
    top: "40%",
    left: "20%",
  },
  "&:hover": {
    background: "white",
    color: "black",
    border: "1px solid black",
  },
}));

export const SecondContent = styled(Box)({
  marginTop: "0%",
  padding: "20px",
  width: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const SecondContentContainer = styled(Box)({
  background: "#dbdbdb",
  height: "100%",
  width: "90%",
  borderRadius: "10px",
});

export const BlackOverlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: "0",
});
