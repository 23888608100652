import React from "react";
import {
  ScienceContainer,
  ScienceHeader,
  ScienceCardSection,
  CardSectionOne,
} from "./ScienceStyle";
import { Typography } from "@mui/material";
import ScienceCard from "./ScienceCard";
import ImageSafety from "../../public/Assets/Images/security.jpg";
import ImageCompliance from "../../public/Assets/Images/compliance.jpg";
import ImageEfficiency from "../../public/Assets/Images/efficiency.jpg";
import ImageCustomerExperience from "../../public/Assets/Images/customer-experience.jpg";


const ScienceFuture = () => {
  const rowOne = [
    {
      id: 1,
      title: "Safety",
      image: ImageSafety,
      /* contentOne:
        "Customizable Defect Identification uses our FOVIAR AI's capability to recognize all types of defects as defined by the client, and we can continuously tailor the solution to meet specific user needs.", */
      contentOne:
        "Proactive Monitoring and Diagnostics of the system's performance to preemptively identify and resolve potential issues before they affect your operations.",
    },
    {
      id: 2,
      title: "Compliance",
      image: ImageCompliance,
      contentOne:
        "MROs and manufacturers can use our digital twins to maintain accurate documentation, track compliance with regulatory standards, and demonstrate adherence to safety and quality guidelines.",
      contentTwo: "",
    },
  ];

  const rowTwo = [
    {
      id: 1,
      image: ImageEfficiency,
      title: "Efficiency",
      contentOne:
        "Digital twins provide real-time visibility into the condition and performance of components to optimize maintenance schedules, prioritize tasks based on criticality, and reduce unnecessary maintenance activities, leading to cost savings.",
      contentTwo: "",
    },
    {
      id: 2,
      image: ImageCustomerExperience,
      title: "Customer Experience",
      contentOne:
        "Upgrading the materials your customers or clients interact with to provide real-time biometric updates, elevates their experience, ensuring their raised expectations for comfort and care are met.",
      contentTwo: "",
    },
  ];
  return (
    <ScienceContainer>
      <ScienceHeader>
        <Typography
          variant="h5"
          sx={{
            width: { lg: "80%", xs: "90%" },
            marginTop: "2%",
            fontSize: { lg: "2.5rem", xs: "1.75rem" },
            color: "#ededed",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "IBM Plex Sans ",
          }}
        >
          AI & material science are shaping the future of industry
        </Typography>
        <Typography
          sx={{
            width: { lg: "70%", xs: "90%" },
            marginTop: "1%",
            fontSize: { lg: "1.25rem", xs: "1rem" },
            // fontSize: "1.25rem",
            fontWeight: "400",
            color: "white",
            textAlign: "center",
            fontFamily: "monospace",
          }}
        >
          Our powerful AI-interface allows industry to keep pace with the
          evolution.
        </Typography>
      </ScienceHeader>
      <ScienceCardSection>
        <CardSectionOne>
          {rowOne.map((item) => (
            <ScienceCard
              key={item.id}
              title={item.title}
              imageSrc={item.image}
              contentOne={item.contentOne}
              contentTwo={item.contentTwo}
            />
          ))}
        </CardSectionOne>
        <CardSectionOne>
          {rowTwo.map((item) => (
            <ScienceCard
              key={item.id}
              imageSrc={item.image}
              title={item.title}
              contentOne={item.contentOne}
              contentTwo={item.contentTwo}
            />
          ))}
        </CardSectionOne>
      </ScienceCardSection>
    </ScienceContainer>
  );
};

export default ScienceFuture;
