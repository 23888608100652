import React, { useState } from "react";
import {
  WorkFlowContainer,
  WorkFlowContent,
  WorkFlowHeader,
  WorkFlowCardComp,
  CardNotExp,
  CardExp,
  WorkFlowRight,
  WorkFlowLeftCards,
  ImageBox,
} from "./WorkflowStyle";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Img1 from "../../public/Assets/Images/consultation.jpg";
import Img2 from "../../public/Assets/Images/action.jpg";
import Img3 from "../../public/Assets/Images/poc.jpg";
import Img4 from "../../public/Assets/Images/after-sale.jpg";
// import WorkFlowCard from "./WorkFlowCard";

const WorkflowHero = () => {
  const [cardStateOne, setCardStateOne] = useState(false);
  const [cardStateTwo, setCardStateTwo] = useState(true);
  const [cardStateThree, setCardStateThree] = useState(true);
  const [cardStateFour, setCardStateFour] = useState(true);
  const [imageState, setImageState] = useState(0);

  const cardOneSwitch = () => {
    setCardStateOne(false);
    setImageState(0);
    setCardStateTwo(true);
    setCardStateThree(true);
    setCardStateFour(true);
  };

  const cardTwoSwitch = () => {
    setCardStateTwo(false);
    setImageState(1);
    setCardStateOne(true);
    setCardStateThree(true);
    setCardStateFour(true);
  };

  const cardThreeSwitch = () => {
    setCardStateThree(false);
    setImageState(2);
    setCardStateOne(true);
    setCardStateTwo(true);
    setCardStateFour(true);
  };

  const cardFourSwitch = () => {
    setCardStateFour(false);
    setImageState(3);
    setCardStateOne(true);
    setCardStateTwo(true);
    setCardStateThree(true);
  };

  const imgArray = [Img1, Img2, Img3, Img4];

  const cardContent = [
    {
      id: 1,
      title: "Consultation",
      content:
        "We seek to create a collaborative partnership from the very first stages of each project: every client is different and our workflow begins with our expert engineers learning what you need to achieve.",
      state: cardStateOne,
      onChange: cardOneSwitch,
    },
    {
      id: 2,
      title: "Action Plan + Research",
      content:
        "We define our goals, timelines and key milestones for the delivery of the project - ensuring all stakeholders are engaged and deliverables are transparent. Our team then commences in-depth research to begin constructing the AI-driven solution you need.",
      state: cardStateTwo,
      onChange: cardTwoSwitch,
    },
    {
      id: 3,
      title: "Prototype + POC",
      content:
        "We then produce a prototype - and validate the solution through the POC stage.",
      state: cardStateThree,
      onChange: cardThreeSwitch,
    },

    {
      id: 4,
      title: "After-Sales Support",
      content:
        "But it doesn’t end there: our after-sales maintenance sets us apart. We establish a direct feedback loop for continuous improvement with our clients, allowing us to refine our services and solutions based on your experiences and suggestions.",
      state: cardStateFour,
      onChange: cardFourSwitch,
    },
  ];
  return (
    <WorkFlowContainer>
      <WorkFlowContent>
        <WorkFlowHeader>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              color: "#ededed",
              fontSize: { lg: "2.5rem", sm: "1.5rem" },
              fontFamily:"IBM Plex Sans"
            }}
          >
            Implementation Workflow
          </Typography>
          <Typography
            variant="span"
            sx={{
              fontStyle: 'italic',
              color: "white",
              width: { lg: "55%", sm: "90%" },
              fontSize: { lg: "1.25rem", sm: "1rem", xs: "1rem" },
              marginTop: "2%",
              textAlign: "center",
              fontFamily:"monospace"
            }}
          >
            Innovating with FOVIATECH: explore your next solution with our
            digital twin software and graphene AI platforms
          </Typography>
        </WorkFlowHeader>
        <WorkFlowCardComp>
          <WorkFlowLeftCards>
            {cardContent.map((item) =>
              item.state ? (
                <CardNotExp cardState={item.state} onClick={item.onChange}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="span"
                      sx={{ fontSize: "1.5rem", marginLeft: "7%" }}
                    >
                      {item.title}
                    </Typography>
                    <AddIcon fontSize="large" sx={{ marginRight: "7%" }} />
                  </Box>
                </CardNotExp>
              ) : (
                <CardExp
                  className={!item.state ? "" : "hide"}
                  cardState={item.state}
                  onClick={item.onChange}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: "4%",
                      fontWeight: "700",
                    }}
                  >
                    <Typography
                      variant="span"
                      sx={{ fontSize: "1.5rem", marginLeft: "7%" }}
                    >
                      {item.title}
                    </Typography>
                    <RemoveIcon sx={{ marginRight: "7%" }} fontSize="large" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "left",
                      // marginLeft: "15%",
                      fontSize: "1rem",
                    }}
                  >
                    <Typography
                      variant="span"
                      sx={{ width: "85%", fontWeight: "400" }}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                </CardExp>
              )
            )}
          </WorkFlowLeftCards>
          <WorkFlowRight>
            <ImageBox>
              <img
                className={imageState ? "" : "hide"}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "15px",
                  backgroundSize: "cover",
                  objectFit: "cover",
                }}
                src={imgArray[imageState]}
                alt="box"
              />
            </ImageBox>
          </WorkFlowRight>
        </WorkFlowCardComp>
      </WorkFlowContent>
    </WorkFlowContainer>
  );
};
export default WorkflowHero;

// <WorkFlowSectionOne>

//         </WorkFlowSectionOne>
//         <WorkFlowSectionTwo>
//           {cardContent.map((item) => (
//             <WorkFlowCard
//               title={item.title}
//               content={item.content}
//               key={item.id}
//             />
//           ))}
//         </WorkFlowSectionTwo>

// {!cardStateOne ? (
//               <CardNotExp cardStateOne={cardStateOne} onClick={cardSwitch}>
//                 <Typography variant="span" sx={{ fontSize: "1.5rem" }}>
//                   Consultation
//                 </Typography>
//                 <AddIcon fontSize="large" />
//               </CardNotExp>
//             ) : (
//               <CardExp cardStateOne={cardStateOne} onClick={cardSwitch}>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-around",
//                     marginTop: "4%",
//                     fontWeight: "700",
//                   }}
//                 >
//                   <Typography variant="span" sx={{ fontSize: "1.5rem" }}>
//                     Consultation
//                   </Typography>
//                   <RemoveIcon fontSize="large" />
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     width: "80%",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     textAlign: "left",
//                     marginLeft: "15%",
//                     fontSize: "1.1rem",
//                   }}
//                 >
//                   We seek to create a collaborative partnership from the very
//                   first stages of each project: every client is different and
//                   our workflow begins with our expert engineers learning what
//                   you need to achieve.
//                 </Box>
//               </CardExp>
//             )}
//             <CardNotExp>
//               <Typography variant="span" sx={{ fontSize: "1.5rem" }}>
//                 Consultation
//               </Typography>
//               <AddIcon fontSize="large" />
//             </CardNotExp>
//             <CardNotExp>
//               <Typography variant="span" sx={{ fontSize: "1.5rem" }}>
//                 Consultation
//               </Typography>
//               <AddIcon fontSize="large" />
//             </CardNotExp>
//             <CardNotExp>
//               <Typography variant="span" sx={{ fontSize: "1.5rem" }}>
//                 Consultation
//               </Typography>
//               <AddIcon fontSize="large" />
//             </CardNotExp>
