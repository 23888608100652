import React from "react";
import {
  ContactContainer,
  UsedContainer,
  CompanyCont,
  TouchContainer,
  TouchButton,
  MarqueeTypography,
} from "./StyleContact";
import TestimonialHero from "../Testimonial-Hero/TestmonialHero";
import { Typography } from "@mui/material";
import { BlackButton } from "../Hero-Section/HeroStyle";

const ContactHero = () => {
  return (
    <ContactContainer>
      <TouchContainer>
        <Typography
          variant="span"
          sx={{
            width: { lg: "90%", xs: "100%" },
            fontSize: { lg: "1.5rem", xs: "1rem" },
            textAlign: "center",
            color: "white",
            marginTop: { xs: "5%", lg: "0%" },
            marginLeft: { xs: "2%" },
            fontWeight: "500",
            fontFamily: "IBM Plex Sans",
          }}
        >
          Our team is passionate about understanding the needs of your project
          and adopting a collaborative approach to deliver the next generation
          of AI-interfaces.
        </Typography>
        <Typography
          variant="span"
          sx={{
            width: { lg: "90%", xs: "100%" },
            fontSize: { lg: "0.8rem", xs: "0.5rem" },
            textAlign: "center",
            color: "white",
            marginTop: { xs: "0%", lg: "-2%" },
            marginLeft: { xs: "2%" },
            fontFamily: "monospace",
            fontWeight: 400,
          }}
        >
          <br></br>
          <br></br>
          Speak to our team about how your vision for integrating AI into your
          organization’s processes, manufacturing, maintenance and design
        </Typography>
        <TouchButton>Get In Touch</TouchButton>
      </TouchContainer>
    </ContactContainer>
  );
};

export default ContactHero;

// <TestimonialHero />
//       <UsedContainer>
//         <Typography
//           variant="h6"
//           sx={{
//             width: "100%",
//             height: "20%",
//             fontSize: "1.25rem",
//             fontWeight: "700",
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "center",
//             marginTop: "2%",
//           }}
//         >
//           Used by the world's most average companies
//         </Typography>
//         <CompanyCont>
//           <MarqueeTypography>Company One</MarqueeTypography>
//           <MarqueeTypography>Company Two</MarqueeTypography>
//           <MarqueeTypography>Company Three</MarqueeTypography>
//           <MarqueeTypography>Company Four</MarqueeTypography>
//         </CompanyCont>
//       </UsedContainer>
