import React from "react";
import {
  HeroDiv,
  ContentBox,
  BlackButton,
  BlackOverlay,
  GlassBox,
} from "./HeroStyle";
import { Box, Typography } from "@mui/material";
import { Element } from "react-scroll";
import Video from "../../public/Assets/Video/106.mp4";

const VideoComp = ({ videoSrc, children }) => {
  return (
    <Box
      sx={{
        width: { lg: "40%", xs: "90%", sm: "40%" },
        height: { lg: "80%", xs: "60%", sm: "80%" },
        position: "absolute",
        marginRight: "10%",
        top: { lg: "10%", md: "10%", xs: "35%", sm: "10%" },
        right: "-5%",
        background: "transparent",
        overflow: "hidden",
        borderRadius: "15px",
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      {children}
    </Box>
  );
};

const HeroSection = () => {
  return (
    <Element name="heroSection">
      <HeroDiv>
        <BlackOverlay />
        <ContentBox>
          <video autoPlay loop muted>
            <source src={Video} type="video/mp4" />
          </video>
          <GlassBox />
          <Box
            sx={{
              width: { lg: "50%", xs: "100%" },
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: { lg: "30%", xs: "10%" },
              left: { lg: "5%", xs: "0%" },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                left: "0%",
                fontWeight: "700",
                marginLeft: "5%",

                width: { xs: "90%", lg: "70%", sm: "50%" },
                fontSize: {
                  md: "2.75rem",
                  lg: "2.5rem",
                  xs: "1.5rem",
                  sm: "2.25rem",
                },

                // color: { lg: "red" },
                marginRight: { xs: "10%", lg: "0%", sm: "0%" },
                textAlign: "left",
              }}
            >
              We help companies integrate the power of AI platforms{" "}
              <Typography
                variant="span"
                sx={{
                  fontSize: {
                    md: "2rem",
                    lg: "2rem",
                    xs: "1rem",
                    sm: "2rem",
                  },
                  marginTop: { xs: "2%", lg: "1%" },
                }}
                style={{
                  fontWeight: "200",
                  position: "absolute",
                  marginLeft: "2%",
                }}
              >
                into manufacturing,
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: {
                    md: "2rem",
                    lg: "2rem",
                    xs: "1rem",
                    sm: "1.8rem",
                  },
                  fontWeight: "200",
                  fontFamily: "'IBM Plex Sans', sans-serif",

                  // background: "red",
                }}
              >
                maintenance and Sensor IOT design solutions
              </Typography>
            </Typography>
          </Box>
          {/*           <BlackButton>Get In Touch</BlackButton>
           */}{" "}
          <VideoComp videoSrc={Video}></VideoComp>
        </ContentBox>
      </HeroDiv>
    </Element>
  );
};

export default HeroSection;

// <Box
//             sx={{
//               position: "absolute",
//               top: "48%",
//               left: "5%",

//               width: { md: "40%", xs: "90%" },
//               display: "flex",
//               marginTop: { md: "5%", sm: "5%", xs: "10%" },
//               justifyContent: "center",
//               marginRight: { xs: "10%", lg: "0" },
//             }}
//           >
//             <span>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontWeight: "700",
//                   fontSize: { md: "1.25rem", xs: "1rem" },
//                 }}
//               >
//                 FOVIAR:{" "}
//               </Typography>
//             </span>{" "}
//             <Typography
//               variant="h6"
//               sx={{
//                 fontWeight: "500",
//                 fontSize: { md: "1.25rem", xs: "1rem", textAlign: "left" },
//               }}
//             >
//               &nbsp;&nbsp;&nbsp;AI digital twin platform to create agile and
//               accurate parts visual inspection
//             </Typography>
//           </Box>
//           <Box
//             sx={{
//               position: "absolute",
//               top: "60%",
//               left: "5%",
//               width: { md: "40%", xs: "90%" },
//               display: "flex",
//               marginTop: { md: "5%", sm: "5%", xs: "10%" },
//               justifyContent: "center",
//               marginRight: { xs: "10%", lg: "0%" },
//             }}
//           >
//             <span>
//               <Typography
//                 variant="h6"
//                 sx={{
//                   fontWeight: "700",
//                   fontSize: { md: "1.25rem", xs: "1rem" },
//                 }}
//               >
//                 FOVGRAI:{" "}
//               </Typography>
//             </span>{" "}
//             <Typography
//               variant="h6"
//               sx={{
//                 fontWeight: "500",
//                 fontSize: { md: "1.25rem", xs: "1rem" },
//               }}
//             >
//               &nbsp;&nbsp;&nbsp;Graphene-based AI interface, the next leap
//               forward in ergonomics design and data analytics
//             </Typography>
//           </Box>

// <FirstContent>
//   <Typography
//     variant="h4"
//     sx={{
//       fontWeight: "bold",
//       display: "flex",
//       width: { xs: "90%", lg: "90%" },
//       fontSize: { md: "2.5rem", xs: "1.5rem" },
//       marginRight: { xs: "10%", lg: "0%" },
//       textAlign: "center",
//     }}
//   >
//     We help companies integrate the power of AI platforms into manufacturing,
//     maintenance and Sensor IOT design solutions:
//   </Typography>
//   <Box
//     sx={{
//       width: { md: "100%", xs: "90%" },
//       display: "flex",
//       marginTop: { md: "5%", sm: "5%", xs: "10%" },
//       justifyContent: "center",
//       marginRight: { xs: "10%", lg: "13.5%" },
//     }}
//   >
//     <span>
//       <Typography
//         variant="h6"
//         sx={{
//           fontWeight: "700",
//           fontSize: { md: "1.25rem", xs: "1rem" },
//         }}
//       >
//         FOVIAR:{" "}
//       </Typography>
//     </span>{" "}
//     <Typography
//       variant="h6"
//       sx={{
//         fontWeight: "500",
//         fontSize: { md: "1.25rem", xs: "1rem" },
//       }}
//     >
//       &nbsp;&nbsp;&nbsp;AI digital twin platform to create agile and accurate
//       parts visual inspection
//     </Typography>
//   </Box>
// <Box
//   sx={{
//     width: { md: "100%", xs: "90%" },
//     display: "flex",
//     marginTop: { md: "5%", sm: "5%", xs: "10%" },
//     justifyContent: "center",
//     marginRight: { xs: "10%", lg: "0%" },
//   }}
// >
//   <span>
//     <Typography
//       variant="h6"
//       sx={{
//         fontWeight: "700",
//         fontSize: { md: "1.25rem", xs: "1rem" },
//       }}
//     >
//       FOVGRAI:{" "}
//     </Typography>
//   </span>{" "}
//   <Typography
//     variant="h6"
//     sx={{
//       fontWeight: "500",
//       fontSize: { md: "1.25rem", xs: "1rem" },
//     }}
//   >
//     &nbsp;&nbsp;&nbsp;Graphene-based AI interface, the next leap forward in
//     ergonomics design and data analytics
//   </Typography>
// </Box>
//   <BlackButton>Get In Touch</BlackButton>
// </FirstContent>;
