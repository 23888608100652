// import React, { useState } from "react";
// import {
//   FoviaHeaderContainer,
//   FoviarContentContainer,
//   FoviarMainContainer,
//   FoviarCardLayout,
//   FoviarCard,
//   FoviarCardHeader,
//   HoverContent,
// } from "./FoviarStyle";
// import ImageOne from "../../public/Assets/Images/pexels-michelangelo-buonarroti-8728382.jpg";
// import ImageTwo from "../../public/Assets/Images/pexels-google-deepmind-17483848.jpg";
// import { Box, List, ListItem, Typography } from "@mui/material";

// const FoviarHero = () => {
//   const [cardOneWidth, setCardOneWidth] = useState("50%");
//   const [cardTwoWidth, setCardTwoWidth] = useState("50%");
//   const [showGlassmorphicBoxOne, setShowGlassmorphicBoxOne] = useState(false);
//   const [showGlassmorphicBoxTwo, setShowGlassmorphicBoxTwo] = useState(false);

//   const handleClickOne = () => {
//     setCardOneWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
//     setCardTwoWidth("50%");
//     setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
//     if (showGlassmorphicBoxTwo) {
//       setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
//     }
//   };

//   const handleClickTwo = () => {
//     setCardTwoWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
//     setCardOneWidth("50%");
//     setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
//     if (showGlassmorphicBoxOne) {
//       setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
//     }
//   };

//   return (
//     <FoviarMainContainer>
//       <FoviaHeaderContainer>
//         <Typography
//           variant="h3"
//           sx={{
//             fontSize: { lg: "3.5rem", xs: "1.5rem" },
//             fontWeight: "700",
//             color: "white",
//           }}
//         >
//           FOVIAR - AI-driven Digital Twin
//         </Typography>
//       </FoviaHeaderContainer>
//       <FoviarContentContainer>
//         <FoviarCardLayout>
//           <FoviarCard onClick={handleClickOne} style={{ width: cardOneWidth }}>
//             <Box
//               sx={{
//                 width: "100%",
//                 height: "100%",
//                 borderRadius: "15px",
//                 backgroundImage: `url(${ImageOne})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 backgroundRepeat: "no-repeat",
//               }}
//             />
//             {showGlassmorphicBoxOne && (
//               <HoverContent
//                 className={showGlassmorphicBoxOne ? "" : "hide"}
//                 onClose={() => setShowGlassmorphicBoxOne(false)}
//               >
//   <Typography
//     variant="h6"
//     sx={{
//       fontWeight: "700",
//       fontSize: "4.5rem",
//       marginLeft: "4%",
//       marginTop: "5%",
//       animationDelay: showGlassmorphicBoxOne ? "2s" : "0s",
//     }}
//   >
//     MRO
//   </Typography>
//   <Typography
//     variant="span"
//     sx={{
//       fontWeight: "500",
//       fontSize: "1.25rem",
//       width: "80%",
//       marginLeft: "4%",
//       animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
//     }}
//   >
//     AI digital twin technology supports MROs to enhance
//     operational efficiency, improve maintenance effectiveness, and
//     enact proactive decision-making.
//   </Typography>
//   <Typography
//     variant="span"
//     sx={{
//       fontWeight: "500",
//       fontSize: "1.25rem",
//       width: "80%",
//       marginTop: "2%",
//       marginLeft: "4%",
//       animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
//     }}
//   >
//     Digital twins can replicate, for example, the physical assets
//     of an aircraft, including engines, components, and systems, in
//     a virtual environment.
//   </Typography>
//   <List
//     sx={{
//       listStyleType: "disc",
//       marginTop: "2%",
//       fontSize: "1.25rem",
//       marginLeft: "6%",
//       animationDelay: showGlassmorphicBoxOne ? "3s" : "0s",
//     }}
//   >
//     <ListItem sx={{ display: "list-item" }}>
//       Rapid visual parts inspection by technicians.
//     </ListItem>
//     <ListItem sx={{ display: "list-item" }}>
//       Predictive maintenance by continuously monitoring and
//       analyzing data.
//     </ListItem>
//     <ListItem sx={{ display: "list-item" }}>
//       Remote diagnostics and troubleshooting capabilities.
//     </ListItem>
//     <ListItem sx={{ display: "list-item" }}>
//       Enable faster problem resolution and minimize aircraft
//       downtime.
//     </ListItem>
//   </List>
// </HoverContent>
//             )}
//           </FoviarCard>
//           <FoviarCard
//             className={showGlassmorphicBoxOne ? "" : "hide"}
//             onClick={handleClickTwo}
//             style={{ width: cardTwoWidth }}
//           >
//             <Box
//               sx={{
//                 width: "100%",
//                 height: "100%",
//                 borderRadius: "15px",
//                 // background: "red",
//                 backgroundImage: `url(${ImageTwo})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 backgroundRepeat: "no-repeat",
//               }}
//             />
//             {showGlassmorphicBoxTwo && (
//               <HoverContent onClose={() => setShowGlassmorphicBoxTwo(false)}>
// <Typography
//   variant="h6"
//   sx={{
//     fontWeight: "700",
//     fontSize: "4.5rem",
//     marginLeft: "4%",
//     marginTop: "5%",
//   }}
// >
//   MANUFACTURING
// </Typography>
// <Typography
//   variant="span"
//   sx={{
//     fontWeight: "500",
//     fontSize: "1.25rem",
//     width: "80%",
//     marginLeft: "4%",
//   }}
// >
//   Digital twins enable manufacturers to monitor the quality of
//   their products on the assembly line and the performance of
//   their products, systems and components over time.
// </Typography>

// <List
//   sx={{
//     listStyleType: "disc",
//     marginTop: "2%",
//     marginLeft: "6%",
//     fontSize: "1.25rem",
//   }}
// >
//   <ListItem sx={{ display: "list-item" }}>
//     Integrate compliance standards with visual inspection.
//   </ListItem>
//   <ListItem sx={{ display: "list-item" }}>
//     Flexibility to add new fault detection categories as they
//     arise.
//   </ListItem>
//   <ListItem sx={{ display: "list-item" }}>
//     Proactive monitoring & diagnostics to ensure system
//     performance.
//   </ListItem>
//   <ListItem sx={{ display: "list-item" }}>
//     Easy-to-use interfaces integrating with IOS & Android and
//     Industrial Glasses.
//   </ListItem>
// </List>
//               </HoverContent>
//             )}
//           </FoviarCard>
//         </FoviarCardLayout>
//       </FoviarContentContainer>
//       <FoviarCardHeader>
//         <Typography
//           variant="h6"
//           sx={{
//             fontSize: "2rem",
//             fontWeight: "400",
//             position: "relative",
//             bottom: "4.5rem",
//             cursor: "pointer",
//           }}
//           onClick={handleClickOne}
//         >
//           MRO
//         </Typography>
//         <Typography
//           onClick={handleClickTwo}
//           variant="h6"
//           sx={{
//             fontSize: "2rem",
//             fontWeight: "400",
//             position: "relative",
//             bottom: "4.5rem",
//             cursor: "pointer",
//           }}
//         >
//           MANUFACTURING
//         </Typography>
//       </FoviarCardHeader>
//     </FoviarMainContainer>
//   );
// };

// export default FoviarHero;

import React, { useState, useEffect } from "react";
import {
  FoviaHeaderContainer,
  FoviarContentContainer,
  FoviarMainContainer,
  FoviarCardLayout,
  FoviarCard,
  FoviarCardHeader,
  HoverContent,
} from "./FoviarStyle";
import ImageOne from "../../public/Assets/Images/mro.png";
import ImageTwo from "../../public/Assets/Images/manufacturing.jpg";
import { Box, List, ListItem, Typography } from "@mui/material";

const FoviarHero = () => {
  const [cardOneWidth, setCardOneWidth] = useState("50%");
  const [cardTwoWidth, setCardTwoWidth] = useState("50%");
  const [showGlassmorphicBoxOne, setShowGlassmorphicBoxOne] = useState(false);
  const [showGlassmorphicBoxTwo, setShowGlassmorphicBoxTwo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleClickOne = () => {
    setCardOneWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
    setCardTwoWidth("50%");
    setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
    if (showGlassmorphicBoxTwo) {
      setShowGlassmorphicBoxTwo(false);
    }
  };

  const handleClickTwo = () => {
    setCardTwoWidth((prevWidth) => (prevWidth === "50%" ? "90%" : "50%"));
    setCardOneWidth("50%");
    setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
    if (showGlassmorphicBoxOne) {
      setShowGlassmorphicBoxOne(false);
    }
  };

  const handleMobileClickOne = () => {
    setCardTwoWidth((prevWidth) => (prevWidth === "90%" ? "90%" : "90%"));
    setCardOneWidth("90%");
    setShowGlassmorphicBoxOne(!showGlassmorphicBoxOne);
    if (showGlassmorphicBoxTwo) {
      setShowGlassmorphicBoxTwo(false);
    }
  };

  const handleMobileClickTwo = () => {
    setCardOneWidth((prevWidth) => (prevWidth === "90%" ? "90%" : "90%"));
    setCardTwoWidth("90%");
    setShowGlassmorphicBoxTwo(!showGlassmorphicBoxTwo);
    if (showGlassmorphicBoxOne) {
      setShowGlassmorphicBoxOne(false);
    }
  };
  useEffect(() => {
    console.log("hi", window.innerWidth);
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 600);
      if (screenWidth <= 600) {
        setCardOneWidth("90%");
        setCardTwoWidth("90%");
      } else {
        // Set your default widths for larger screens
        setCardOneWidth("50%");
        setCardTwoWidth("50%");
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <FoviarMainContainer>
      <FoviaHeaderContainer>
        <Typography
          variant="h3"
          sx={{
            fontSize: { lg: "3.5rem", xs: "2rem" },
            fontWeight: "700",
            color: "#ededed",
            fontFamily: "monospace",
            textAlign: { xs: "center" },
          }}
        >
          FOVIAR
        </Typography>
        <Typography
          variant="h3"
          sx={{
            marginTop: { xs: "5%", lg: "0%" },
            fontSize: { lg: "3rem", xs: "1.5rem" },
            fontWeight: "500",
            color: "#00c3ca",
            textAlign: { xs: "center" },
            fontFamily: "monospace",
          }}
        >
          &nbsp;AI-driven Digital Twin
        </Typography>
      </FoviaHeaderContainer>
      <FoviarContentContainer>
        <FoviarCardLayout>
          {isMobile ? (
            <>
              <FoviarCard
                onClick={handleMobileClickOne}
                style={{ width: cardOneWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${ImageOne})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    zIndex: "2",
                  }}
                />
                {!showGlassmorphicBoxOne ? (
                  <Typography
                    variant="h1"
                    sx={{
                      width: "100%",
                      fontWeight: "700",
                      // height: "100%",
                      // background: "red",
                      zIndex: "100",
                      fontSize: "2rem",
                      position: "absolute",
                      top: "35%",
                      left: "38%",
                      fontFamily: "IBM Plex Sans",
                      // left: "100%",
                    }}
                  >
                    Mro
                  </Typography>
                ) : null}

                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickOne}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "30%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}

                {showGlassmorphicBoxOne && (
                  <HoverContent
                    className={showGlassmorphicBoxOne ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxOne(false)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        marginLeft: "4%",
                        marginTop: "5%",
                        fontFamily: "IBM Plex Sans",
                        animationDelay: showGlassmorphicBoxOne ? "2s" : "0s",
                      }}
                    >
                      Mro
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                        width: "90%",
                        marginLeft: "4%",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      AI digital twin technology supports MROs to enhance
                      operational efficiency, improve maintenance effectiveness,
                      and enact proactive decision-making.
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                        width: "90%",
                        marginTop: "2%",
                        marginLeft: "4%",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      Digital twins can replicate, for example, the physical
                      assets of an aircraft, including engines, components, and
                      systems, in a virtual environment.
                    </Typography>
                    <List
                      sx={{
                        listStyleType: "disc",
                        marginTop: "0%",
                        fontSize: "0.8rem",
                        marginLeft: "6%",
                        animationDelay: showGlassmorphicBoxOne ? "3s" : "0s",
                      }}
                    >
                      <ListItem sx={{ display: "list-item" }}>
                        Rapid visual parts inspection by technicians.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Predictive maintenance by continuously monitoring and
                        analyzing data.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Remote diagnostics and troubleshooting capabilities.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Enable faster problem resolution and minimize aircraft
                        downtime.
                      </ListItem>
                    </List>
                  </HoverContent>
                )}
              </FoviarCard>
              <FoviarCard
                onClick={handleMobileClickTwo}
                style={{ width: cardTwoWidth }}
              >
                {!showGlassmorphicBoxTwo ? (
                  <Typography
                    variant="h1"
                    sx={{
                      width: "100%",
                      fontWeight: "700",
                      // height: "100%",
                      // background: "red",
                      zIndex: "100",
                      fontSize: "2rem",
                      position: "absolute",
                      top: "38%",
                      left: "4%",
                      // left: "100%",
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    Manufacturing
                  </Typography>
                ) : null}

                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickTwo}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "30%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${ImageTwo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {showGlassmorphicBoxTwo && (
                  <HoverContent
                    className={showGlassmorphicBoxTwo ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxTwo(false)}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1.5rem",
                        marginLeft: "4%",
                        marginTop: "5%",
                        fontWeight: "700",
                      }}
                    >
                      Manufacturing
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "0.8rem",
                        width: "80%",
                        marginLeft: "4%",
                      }}
                    >
                      Digital twins enable manufacturers to monitor the quality
                      of their products on the assembly line and the performance
                      of their products, systems and components over time.
                    </Typography>

                    <List
                      sx={{
                        listStyleType: "disc",
                        marginTop: "2%",
                        marginLeft: "6%",
                        fontSize: "0.8rem",
                      }}
                    >
                      <ListItem sx={{ display: "list-item" }}>
                        Integrate compliance standards with visual inspection.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Flexibility to add new fault detection categories as
                        they arise.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Proactive monitoring & diagnostics to ensure system
                        performance.
                      </ListItem>
                      <ListItem sx={{ display: "list-item" }}>
                        Easy-to-use interfaces integrating with IOS & Android
                        and Industrial Glasses.
                      </ListItem>
                    </List>
                  </HoverContent>
                )}
              </FoviarCard>
            </>
          ) : (
            <>
              <FoviarCard
                onClick={handleClickOne}
                style={{ width: cardOneWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${ImageOne})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {!showGlassmorphicBoxOne ? (
                  <button
                    onClick={handleClickOne}
                    style={{
                      position: "absolute",
                      top: "45%",
                      left: "30%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}

                {showGlassmorphicBoxOne && (
                  <HoverContent
                    className={showGlassmorphicBoxOne ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxOne(false)}
                  >
                    {/*<Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "4.5rem",
                        marginLeft: "4%",
                        marginTop: "2%",
                        animationDelay: showGlassmorphicBoxOne ? "2s" : "0s",
                      }}
                    >
                      MRO
                    </Typography>*/}
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "1.1rem",
                        width: "80%",

                        marginLeft: "7%",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      AI digital twin technology supports MROs to enhance
                      operational efficiency, improve maintenance effectiveness,
                      and enact proactive decision-making.
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontWeight: "500",
                        fontSize: "1.1rem",
                        width: "80%",
                        marginTop: "4%",

                        marginLeft: "7%",
                        animationDelay: showGlassmorphicBoxOne ? "2.5s" : "0s",
                      }}
                    >
                      Digital twins can replicate, for example, the physical
                      assets of an aircraft, including engines, components, and
                      systems, in a virtual environment.
                    </Typography>
                    <List
                      sx={{
                        listStyleType: "disc",
                        marginTop: "2%",
                        fontSize: "1.1rem",
                        marginLeft: "10%",
                        animationDelay: showGlassmorphicBoxOne ? "3s" : "0s",
                      }}
                    >
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Rapid visual parts inspection by technicians.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Predictive maintenance by continuously monitoring and
                        analyzing data.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Remote diagnostics and troubleshooting capabilities.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Enable faster problem resolution and minimize aircraft
                        downtime.
                      </ListItem>
                    </List>
                  </HoverContent>
                )}
              </FoviarCard>
              <FoviarCard
                className={showGlassmorphicBoxOne ? "" : "hide"}
                onClick={handleClickTwo}
                style={{ width: cardTwoWidth }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    backgroundImage: `url(${ImageTwo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {!showGlassmorphicBoxTwo ? (
                  <button
                    onClick={handleClickTwo}
                    style={{
                      position: "absolute",
                      top: "45%",
                      left: "35%",
                      width: "40%",
                      height: "10%",
                      borderRadius: "50px",
                      border: "none",
                      color: "white",
                      fontWeight: "700",
                      background: "black",
                    }}
                  >
                    Know More
                  </button>
                ) : null}
                {showGlassmorphicBoxTwo && (
                  <HoverContent
                    className={showGlassmorphicBoxTwo ? "" : "hide"}
                    onClose={() => setShowGlassmorphicBoxTwo(false)}
                  >
                    {/*<Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        fontSize: "4.5rem",
                        marginLeft: "4%",
                        marginTop: "5%",
                      }}
                    >
                      MANUFACTURING
                    </Typography>*/}
                    <Typography
                      variant="span"
                      sx={{
                        marginTop: "2%",

                        fontWeight: "500",
                        fontSize: "1.1rem",
                        width: "80%",
                        marginLeft: "7%",
                      }}
                    >
                      Digital twins enable manufacturers to monitor the quality
                      of their products on the assembly line and the performance
                      of their products, systems and components over time.
                    </Typography>

                    <List
                      sx={{
                        listStyleType: "disc",
                        marginTop: "2%",
                        marginLeft: "10%",
                        fontSize: "1.1rem",
                      }}
                    >
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Integrate compliance standards with visual inspection.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Flexibility to add new fault detection categories as
                        they arise.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Proactive monitoring & diagnostics to ensure system
                        performance.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", marginTop: "-2%" }}>
                        Easy-to-use interfaces integrating with IOS & Android
                        and Industrial Glasses.
                      </ListItem>
                    </List>
                  </HoverContent>
                )}
              </FoviarCard>
            </>
          )}
        </FoviarCardLayout>
      </FoviarContentContainer>
      {!isMobile ? (
        <FoviarCardHeader>
          <Typography
            variant="h6"
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              position: "relative",
              bottom: "4.5rem",
              left: "3rem",
              cursor: "pointer",
              fontFamily: "IBM Plex Sans",
            }}
            onClick={handleClickOne}
          >
            MRO
          </Typography>
          <Typography
            onClick={handleClickTwo}
            variant="h6"
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              position: "relative",
              bottom: "4.5rem",
              right: "3rem",
              cursor: "pointer",
              fontFamily: "IBM Plex Sans",
            }}
          >
            MANUFACTURING
          </Typography>
        </FoviarCardHeader>
      ) : null}
    </FoviarMainContainer>
  );
};

export default FoviarHero;
