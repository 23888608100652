import { ThemeProvider } from "@emotion/react";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useRef, useState } from "react";
import theme from "./theme";
import LandingPage from "./Components/Pages/Landing-Page/LandingPage";
import NavBar from "./Components/Navbar/NavBar";
import Imprint from "./Components/Imprint/Imprint";
import styled from "@emotion/styled";
import { Route,Routes } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ScrollToTopButton = styled(Button)({
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "white",
  color: "black",
  borderRadius: "50px",
  width: "10px",
  height: "65px",
  zIndex: "992",
  // border: "none",
  padding: "10px 20px",
  cursor: "pointer",
  fontSize: "16px",
  border: "2px solid transparent",
  transition: "border 0.3s ease-in-out, border-radius 0.3s ease-in-out", // Add border-radius to the transition
  "&:hover": {
    borderImage: "linear-gradient(90deg, #00ff00, #ff0000, #0000ff)",
    borderImageSlice: 4,
    background: "white",
    borderRadius: "50px", // Set the border-radius on hover
  },
});
function App() {
  const [navbarMobile, setNavbarMobile] = useState(false);

  const handleMobile = () => {
    setNavbarMobile(true);
  };

  const handleMobileClose = () => {
    setNavbarMobile(false);
  };

  const topRef = useRef();

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return navbarMobile ? (
    <Box
      onClick={handleMobileClose}
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#113f4e",
        display: "flex",
        flexDirection: "column",
        justifyContent: "",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "10%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          onClick={handleMobileClose}
          variant="span"
          sx={{
            marginRight: "10%",
            marginTop: "5%",
            fontSize: "2rem",
            color: "white",
            cursor: "pointer",
          }}
        >
          X
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "60%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          marginTop: "15%",
        }}
      >
        <Typography
          sx={{
            color: "white",
            width: "100%",
            height: "25%",
            fontSize: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Home
        </Typography>
        <Typography
          sx={{
            color: "white",
            width: "100%",
            height: "25%",
            fontSize: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Research
        </Typography>
        <Typography
          sx={{
            color: "white",
            width: "100%",
            height: "25%",
            fontSize: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Industry
        </Typography>
        <Typography
          sx={{
            color: "white",
            width: "100%",
            height: "25%",
            fontSize: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "700",
          }}
        >
          Company
        </Typography>
      </Box>
      <Button
        color="inherit"
        sx={{
          fontFamily: "Montserrat, sans-serif",
          border: "1px solid black",
          background: "white",
          width: "60%",
          marginTop: "15%",
          marginLeft: "20%",
          height: "5%",
          color: "black",
          "&:hover": {
            borderImage: "linear-gradient(90deg, #00ff00, #ff0000, #0000ff)",
            borderImageSlice: 1,
            background: "white",
          },
        }}
      >
        Login
      </Button>
    </Box>
  ) : (
    <Router>
    <ThemeProvider theme={theme}>
      <NavBar
        navbarMobile={navbarMobile}
        handleMobile={handleMobile}
        handleMobileClose={handleMobileClose}
      />
      <Routes>
        <Route path="/" element={<LandingPage refProp={topRef} />} />
        <Route path="/imprint" element={<Imprint refProp={topRef} />} />
      </Routes>
      <ScrollToTopButton onClick={scrollToTop}>
        <ArrowUpwardIcon fontSize="large" />
      </ScrollToTopButton>
    </ThemeProvider>
    </Router>
  );
}

export default App;
