import styled from "@emotion/styled";
import { Box, Paper, keyframes } from "@mui/material";

const glow = keyframes`
  0% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.3);
  }
`;

export const ScienceContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "160vh",
  background: theme.palette.primary.alternative,
  // background: "red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    height: "155vh",
  },

  // borderBottom: "2px solid black",
}));

export const ScienceHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "10%",
  marginTop: "2%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    // background: "yellow",
    marginTop: "5%",
    height: "35vh",
  },
}));

export const ScienceCardSection = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "90%",
  gap: "1rem",
  marginTop: "5%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {},
}));

export const CardSectionOne = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "45%",

  // background: "blue",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "1rem",
    // background: "yellow",
  },
}));

export const ScienceCardDiv = styled(Paper)(({ theme }) => ({
  width: "80%",
  height: "80%",
  display: "flex",
  flexDirection: "column",
  border: "1px solid black",
  borderRadius: "15px",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "50%",
  },
}));

export const ScienceImageCont = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "50%",
  background: "#dbdbdb",
  display: "flex",
  borderRadius: "15px",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ScienceContentCont = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "flex-start",
  marginLeft: "3%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
