// export const theme = {
//   primaryColor: "#fff",
//   secondaryColor: "#fff",
//   fontFamily: "Montserrat, sans-serif",
// };
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      // alternative: "#1c365a",
      // alternative: "#0a0e12", //dark
      alternative: "#113f4e",
    },
    secondary: {
      main: "#fff",
    },
    appBar: {
      // main: "#0a0e12",
      main: "#116466",
    },
  },
  shape: {
    borderRadius: "15px",
  },
  typography: {
    // fontFamily: "Montserrat, sans-serif",
  },
});

export default theme;
